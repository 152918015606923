const FILTER_TYPE_ADMISSION_BY_CARD = 'admission_by_card';
const FILTER_TYPE_CATEGORIES = 'categories';
const FILTER_TYPE_DATES = 'dates';
const FILTER_TYPE_DESTINATIONS = 'destinations';
const FILTER_TYPE_FOR_ENTHUSIAST = 'for_enthusiast';
const FILTER_TYPE_MODEL_TYPES = 'requestTypes';
const FILTER_TYPE_MONTHS = 'months';
const FILTER_TYPE_ORGANIZER = 'organizer';
const FILTER_TYPE_PARTNER_MUSEUMS = 'partner_museums';
const FILTER_TYPE_PLACE_IN_TIME = 'place_in_time';
const FILTER_TYPE_SORT = 'sort';
const FILTER_TYPE_STAFF_PICK = 'staff_pick';
const FILTER_TYPE_TICKET_REQUEST_STATUS = 'ticketRequestStatus';
const FILTER_TYPE_TIMES = 'times';
const FILTER_TYPE_TYPES = 'types';
const FILTER_TYPE_YEARS = 'years';

export {
  FILTER_TYPE_ADMISSION_BY_CARD,
  FILTER_TYPE_CATEGORIES,
  FILTER_TYPE_DATES,
  FILTER_TYPE_DESTINATIONS,
  FILTER_TYPE_FOR_ENTHUSIAST,
  FILTER_TYPE_MODEL_TYPES,
  FILTER_TYPE_MONTHS,
  FILTER_TYPE_ORGANIZER,
  FILTER_TYPE_PARTNER_MUSEUMS,
  FILTER_TYPE_PLACE_IN_TIME,
  FILTER_TYPE_SORT,
  FILTER_TYPE_STAFF_PICK,
  FILTER_TYPE_TICKET_REQUEST_STATUS,
  FILTER_TYPE_TIMES,
  FILTER_TYPE_TYPES,
  FILTER_TYPE_YEARS,
};
